@font-face {
  font-family: "RobotoMono";
  src: url("../fonts/RobotoMono-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoMono";
  src: url("../fonts/RobotoMono-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style:  normal;
}

@font-face {
  font-family: "RobotoMono";
  src: url("../fonts/RobotoMono-RegularItalic.ttf");
  font-style: italic;
}

@font-face {
  font-family: "RobotoMono";
  src: url("../fonts/RobotoMono-BoldItalic.ttf");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Inter";
  font-style:  normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/Inter-Regular.woff2") format("woff2"),
  url("../fonts/Inter-Regular.ttf") format("truetype"),
  url("../fonts/Inter-Regular.woff") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style:  italic;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/Inter-Italic.woff2") format("woff2"),
  url("../fonts/Inter-Italic.ttf") format("truetype"),
  url("../fonts/Inter-Italic.woff") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style:  normal;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/Inter-Bold.woff2") format("woff2"),
  url("../fonts/Inter-Bold.ttf") format("truetype"),
  url("../fonts/Inter-Bold.woff") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style:  italic;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/Inter-BoldItalic.woff2") format("woff2"),
  url("../fonts/Inter-BoldItalic.ttf") format("truetype"),
  url("../fonts/Inter-BoldItalic.woff") format("woff");
}

@font-face {
  font-family: 'icons';
  src:  url('../fonts/icons.eot');
  src:  url('../fonts/icons.eot#iefix') format('embedded-opentype'),
  url('../fonts/icons.ttf') format('truetype'),
  url('../fonts/icons.woff') format('woff'),
  url('../fonts/icons.svg?#icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
