.breadcrumb-top {
  height: 24px;
  line-height: 24px;
  font-weight: normal;
  font-size: $font-size-default;
  span {
    
    padding: 0 8px;
    font-size: 14px;
    &:before {
      color: $n-7;

    }
    &:hover {
      cursor: default;
    }
  }
}
