.top-bar {
  height: 26px;
  line-height: 26px;

  &:last-child {
    border-bottom: 1px solid $n-3;
  }

  img {
    margin-top: -3px;
    padding: 0;
    line-height: 26px;
    width: 15px;
    margin-right: 8px;
    display: inline-block;
  }

  p {
    color: $black;
    display: inline-block;
    padding: 2px 0 0 0;
  }

  &.menu {
    color: $black;
    font-size: 12px !important;

    .main-menu {
      padding: 0;
      list-style: none;

      li {
        display: inline-block;
        position: relative;
        padding: 0 25px 0 0;

        &:hover {
          ul {
            display: block;
          }
        }

        ul {
          padding: 0;
          display: none;
          margin-top: -1px;
          margin-left: -10px;
          position: absolute;
          z-index: 10;
          background-color: $white;
          border: 1px solid $n-3;

          li {
            padding: 0 30px 0 10px;
            display: block;

            &:hover {
              background-color: $n-1;
            }
          }
        }
      }
    }
  }

  .icon-square {
    padding: 0 10px;

    &:before {
      font-size: 12px;
    }
  }

  .icon-small-minus {
    margin-bottom: -5px;
  }

  .icon-cross {
    &:before {
      font-size: 16px;
    }
  }
}
