.lm_goldenlayout {
}

.lm_content {
  /*  background: $n-1;
 border: 1px solid #cccccc */
}

.lm_dragProxy .lm_content {
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  box-sizing: border-box
}

.lm_dropTargetIndicator {
  box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.4);
  outline: 1px dashed $b-7;
  margin: 1px;
  transition: all 200ms ease
}

.lm_dropTargetIndicator .lm_inner {
  background: #000000;
  opacity: .1
}

.lm_splitter {
  background: $n-1;
  transition: opacity 200ms ease
}

.lm_splitter:hover, .lm_splitter.lm_dragging {
  background: #bbbbbb;
  opacity: 1
}

.lm_header {
  height: 30px;
}

.lm_header.lm_selectable {
  cursor: pointer
}

.lm_header .lm_tab {
  font-family: Arial, sans-serif;
  font-size: 12px;
  color: #888888;
  background: #fafafa;
  margin-right: 2px;
  padding-bottom: 4px;
  border: 1px solid #cccccc;
  border-bottom: none
}

.lm_header .lm_tab .lm_title {
  padding-top: 1px
}
.lm_header {
  background: $n-1;
}
.lm_splitter.lm_horizontal {
}
.lm_header .lm_tab .lm_close_tab {
  width: 11px;
  height: 11px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHCAYAAADEUlfTAAAAIklEQVR4nGNgYGD4z4Ad/Mdg4ODDBXCZRFgCp5EEHQMXBwAQAgz0SVCcggAAAABJRU5ErkJggg==);
  background-position: center center;
  background-repeat: no-repeat;
  right: 6px;
  top: 8px;
  opacity: .4
}

.lm_header .lm_tab .lm_close_tab:hover {
  opacity: 1
}

.lm_header .lm_tab.lm_active {
  border-bottom: none;
  box-shadow: 2px -2px 2px -2px rgba(0, 0, 0, 0.2);
  padding-bottom: 5px
}

.lm_header .lm_tab.lm_active .lm_close_tab {
  opacity: 1
}

.lm_dragProxy.lm_bottom .lm_header .lm_tab.lm_active, .lm_stack.lm_bottom .lm_header .lm_tab.lm_active {
  box-shadow: 2px 2px 2px -2px rgba(0, 0, 0, 0.2)
}

.lm_selected .lm_header {
  background-color: #452500
}

.lm_tab:hover, .lm_tab.lm_active {
  background: #e1e1e1;
  color: #777777
}

.lm_header .lm_controls .lm_tabdropdown:before {
  color: #000000
}

.lm_controls > li {
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  opacity: .4;
  transition: opacity 300ms ease
}

.lm_controls > li:hover {
  opacity: 1
}

.lm_controls .lm_popout {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAYAAADgkQYQAAAANUlEQVR4nI2QMQoAMAwCz5L/f9mOzZIaN0E9UDyZhaaQz6atgBHgambEJ5wBKoS0WaIvfT+6K2MIECN19MAAAAAASUVORK5CYII=)
}

.lm_controls .lm_maximise {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAYAAADgkQYQAAAAIklEQVR4nGNkYGD4z0AAMBFSAAOETPpPlEmDUREjAxHhBABPvAQLFv3qngAAAABJRU5ErkJggg==)
}

.lm_controls .lm_close {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAYAAADgkQYQAAAAKUlEQVR4nGNgYGD4z4Af/Mdg4FKASwCnDf8JKSBoAtEmEXQTQd8RDCcA6+4Q8OvIgasAAAAASUVORK5CYII=)
}

.lm_maximised .lm_header {
  background-color: $b-7;
}

.lm_maximised .lm_controls .lm_maximise {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAYAAADgkQYQAAAAJklEQVR4nGP8//8/AyHARFDFUFbEwsDAwMDIyIgzHP7//89IlEkApSkHEScJTKoAAAAASUVORK5CYII=)
}

.lm_transition_indicator {
  background-color: #000000;
  border: 1px dashed #555555
}

.lm_popin {
  cursor: pointer
}

.lm_popin .lm_bg {
  background: #000000;
  opacity: .7
}

.lm_popin .lm_icon {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAJCAYAAADpeqZqAAAAWklEQVR4nJWOyw3AIAxDHcQC7L8jbwT3AlJBfNp3SiI7dtRaLSlKKeoA1oEsKSQZCEluexw8Tm3ohk+E7bnOUHUGcNh+HwbBygw4AZ7FN/Lt84p0l+yTflV8AKQyLdcCRJi/AAAAAElFTkSuQmCC);
  background-position: center center;
  background-repeat: no-repeat;
  opacity: .7
}

.lm_popin:hover .lm_icon {
  opacity: 1
}

/*# sourceMappingURL=goldenlayout-light-theme.css.map */
