.lm_root {
  margin-top: 40px;

  .lm_header {
  }

  li {
    &.lm_tab {
      height: 100%;
      font-weight: bold;
      background: none !important;
      box-shadow: none;
      border: none !important;
      font-size: 11px;
      line-height: 1.34;
      padding-top: 5px;

      .lm_close_tab {
        background-image: none;


      }
    }

    &.lm_active {
      box-shadow: none !important;
      border-bottom: 2px solid $b-6 !important;
      padding-bottom: 14px;

      .lm_close_tab {
        background-image: url('/assets/img/ui-icons/cross.svg');
        background-size: 14px;
        opacity: 1;
      }

    }
  }


  .lm_header {
   // height: 30px !important;
    border-bottom: 1px solid $n-3;
  }

  .lm_splitter {
    &.lm_vertical {
      margin-bottom: -5px !important;
      height: 1px !important;

      .lm_drag_handle {
        height: 1px !important;
        top: 0;
      }

      &.lm_dragging {
        box-shadow: 1px 1px 2px $n-9;
      }

    }

    &.lm_horizontal {
      border-right: 1px solid $n-3;
      width: 1px !important;

      .lm_drag_handle {
        width: 5px;
        left: 0;
      }

      &.lm_dragging {
        box-shadow: 1px 1px 2px $n-9;
      }

    }
  }
}


