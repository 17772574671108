.lm_root {
  position: relative
}

.lm_row > .lm_item {
  float: left
}

.lm_content {
  overflow: hidden;
  position: relative
}

.lm_dragging, .lm_dragging * {
  cursor: move !important;
  user-select: none
}

.lm_maximised {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 40
}

.lm_maximise_placeholder {
  display: none
}

.lm_splitter {
  position: relative;
  z-index: 20
}

.lm_splitter:hover, .lm_splitter.lm_dragging {
  background: orange
}

.lm_splitter.lm_vertical .lm_drag_handle {
  width: 100%;
  height: 15px;
  position: absolute;
  top: -5px;
  cursor: ns-resize
}

.lm_splitter.lm_horizontal {
  float: left;
  height: 100%
}

.lm_splitter.lm_horizontal .lm_drag_handle {
  width: 15px;
  height: 100%;
  position: absolute;
  left: -5px;
  cursor: ew-resize
}

.lm_header {
  overflow: visible;
  position: relative;
  z-index: 1
}

.lm_header [class^=lm_] {
  box-sizing: content-box !important
}

.lm_header .lm_controls {
  position: absolute;
  right: 3px
}

.lm_header .lm_controls > li {
  cursor: pointer;
  float: left;
  width: 18px;
  height: 18px;
  text-align: center
}

.lm_header ul {
  margin: 0;
  padding: 0;
  list-style-type: none
}

.lm_header .lm_tabs {
  position: absolute
}

.lm_header .lm_tab {
  cursor: pointer;
  float: left;
  height: 14px;
  margin-top: 1px;
  padding: 0 10px 5px;
  padding-right: 25px;
  position: relative
}

.lm_header .lm_tab i {
  width: 2px;
  height: 19px;
  position: absolute
}

.lm_header .lm_tab i.lm_left {
  top: 0;
  left: -2px
}

.lm_header .lm_tab i.lm_right {
  top: 0;
  right: -2px
}

.lm_header .lm_tab .lm_title {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis
}

.lm_header .lm_tab .lm_close_tab {
  width: 14px;
  height: 14px;
  position: absolute;
  top: 0;
  right: 0;
  text-align: center
}

.lm_stack.lm_left .lm_header, .lm_stack.lm_right .lm_header {
  height: 100%
}

.lm_dragProxy.lm_left .lm_header, .lm_dragProxy.lm_right .lm_header, .lm_stack.lm_left .lm_header, .lm_stack.lm_right .lm_header {
  width: 20px;
  float: left;
  vertical-align: top
}

.lm_dragProxy.lm_left .lm_header .lm_tabs, .lm_dragProxy.lm_right .lm_header .lm_tabs, .lm_stack.lm_left .lm_header .lm_tabs, .lm_stack.lm_right .lm_header .lm_tabs {
  transform-origin: left top;
  top: 0;
  width: 1000px
}

.lm_dragProxy.lm_left .lm_header .lm_controls, .lm_dragProxy.lm_right .lm_header .lm_controls, .lm_stack.lm_left .lm_header .lm_controls, .lm_stack.lm_right .lm_header .lm_controls {
  bottom: 0
}

.lm_dragProxy.lm_left .lm_items, .lm_dragProxy.lm_right .lm_items, .lm_stack.lm_left .lm_items, .lm_stack.lm_right .lm_items {
  float: left
}

.lm_dragProxy.lm_left .lm_header .lm_tabs, .lm_stack.lm_left .lm_header .lm_tabs {
  transform: rotate(-90deg) scaleX(-1);
  left: 0
}

.lm_dragProxy.lm_left .lm_header .lm_tabs .lm_tab, .lm_stack.lm_left .lm_header .lm_tabs .lm_tab {
  transform: scaleX(-1);
  margin-top: 1px
}

.lm_dragProxy.lm_left .lm_header .lm_tabdropdown_list, .lm_stack.lm_left .lm_header .lm_tabdropdown_list {
  top: initial;
  right: initial;
  left: 20px
}

.lm_dragProxy.lm_right .lm_content {
  float: left
}

.lm_dragProxy.lm_right .lm_header .lm_tabs, .lm_stack.lm_right .lm_header .lm_tabs {
  transform: rotate(90deg) scaleX(1);
  left: 100%;
  margin-left: 0
}

.lm_dragProxy.lm_right .lm_header .lm_controls, .lm_stack.lm_right .lm_header .lm_controls {
  left: 3px
}

.lm_dragProxy.lm_right .lm_header .lm_tabdropdown_list, .lm_stack.lm_right .lm_header .lm_tabdropdown_list {
  top: initial;
  right: 20px
}

.lm_dragProxy.lm_bottom .lm_header .lm_tab, .lm_stack.lm_bottom .lm_header .lm_tab {
  margin-top: 0;
  border-top: none
}

.lm_dragProxy.lm_bottom .lm_header .lm_controls, .lm_stack.lm_bottom .lm_header .lm_controls {
  top: 3px
}

.lm_dragProxy.lm_bottom .lm_header .lm_tabdropdown_list, .lm_stack.lm_bottom .lm_header .lm_tabdropdown_list {
  top: initial;
  bottom: 20px
}

.lm_drop_tab_placeholder {
  float: left;
  width: 100px;
  height: 10px;
  visibility: hidden
}

.lm_header .lm_controls .lm_tabdropdown:before {
  content: '';
  width: 0;
  height: 0;
  vertical-align: middle;
  display: inline-block;
  border-top: 5px dashed;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  color: white
}

.lm_header .lm_tabdropdown_list {
  position: absolute;
  top: 20px;
  right: 0;
  z-index: 5;
  overflow: hidden
}

.lm_header .lm_tabdropdown_list .lm_tab {
  clear: both;
  padding-right: 10px;
  margin: 0
}

.lm_header .lm_tabdropdown_list .lm_tab .lm_title {
  width: 100px
}

.lm_header .lm_tabdropdown_list .lm_close_tab {
  display: none !important
}

.lm_dragProxy {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 30
}

.lm_dragProxy .lm_header {
  background: transparent
}

.lm_dragProxy .lm_content {
  border-top: none;
  overflow: hidden
}

.lm_dropTargetIndicator {
  display: none;
  position: absolute;
  z-index: 20
}

.lm_dropTargetIndicator .lm_inner {
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  left: 0
}

.lm_transition_indicator {
  display: none;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20
}

.lm_popin {
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 9999
}

.lm_popin > * {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0
}

.lm_popin > .lm_bg {
  z-index: 10
}

.lm_popin > .lm_icon {
  z-index: 20
}

/*# sourceMappingURL=goldenlayout-base.css.map */
