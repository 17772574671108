[class^="icon-"], [class*=" icon-"], .icon-ext {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 16px;
  vertical-align: middle;
  display: inline-block;
}

.icon-add-to-folder:before {
  content: "\e900";
}
.icon-add:before {
  content: "\e901";
}
.icon-align-left:before {
  content: "\e902";
}
.icon-arrow-down:before {
  content: "\e903";
}
.icon-arrow-left:before {
  content: "\e904";
}
.icon-arrow-right:before {
  content: "\e905";
}
.icon-arrow-up:before {
  content: "\e906";
}
.icon-caret-down:before {
  content: "\e907";
}
.icon-caret-left:before {
  content: "\e908";
}
.icon-caret-right:before {
  content: "\e909";
  font-size: 20px;
}
.icon-caret-up:before {
  content: "\e90a";
}
.icon-clipboard:before {
  content: "\e90b";
}
.icon-code:before {
  content: "\e90c";
}
.icon-cog:before {
  content: "\e90d";
}
.icon-console:before {
  content: "\e90e";
}
.icon-cross:before {
  content: "\e90f";
}
.icon-cube-add:before {
  content: "\e910";
}
.icon-cube:before {
  content: "\e911";
}
.icon-document:before {
  content: "\e912";
}
.icon-dot:before {
  content: "\e913";
}
.icon-drag-handle-horizontal:before {
  content: "\e914";
}
.icon-drag-handle-vertical:before {
  content: "\e915";
}
.icon-duplicate:before {
  content: "\e916";
}
.icon-edit:before {
  content: "\e917";
}
.icon-error:before {
  content: "\e918";
}
.icon-export:before {
  content: "\e919";
}
.icon-flag:before {
  content: "\e91a";
}
.icon-folder-close:before {
  content: "\e91b";
}
.icon-folder-new:before {
  content: "\e91c";
}
.icon-folder-open:before {
  content: "\e91d";
}
.icon-folder-shared:before {
  content: "\e91e";
}
.icon-full-circle:before {
  content: "\e91f";
}
.icon-git-branch:before {
  content: "\e920";
}
.icon-git-commit:before {
  content: "\e921";
}
.icon-git-merge:before {
  content: "\e922";
}
.icon-git-new-branch:before {
  content: "\e923";
}
.icon-git-pull:before {
  content: "\e924";
}
.icon-git-push:before {
  content: "\e925";
}
.icon-git-repo:before {
  content: "\e926";
}
.icon-history:before {
  content: "\e927";
}
.icon-issue:before {
  content: "\e928";
}
.icon-maximize:before {
  content: "\e929";
}
.icon-minimize:before {
  content: "\e92a";
}
.icon-more:before {
  content: "\e92b";
}
.icon-more-v:before {
  content: "\e93d";
}
.icon-move:before {
  content: "\e92c";
}
.icon-notifications:before {
  content: "\e92d";
}
.icon-offline:before {
  content: "\e92e";
}
.icon-outdated:before {
  content: "\e92f";
}
.icon-page-layout:before {
  content: "\e930";
}
.icon-plus:before {
  content: "\e931";
}
.icon-refresh:before {
  content: "\e932";
}
.icon-saved:before {
  content: "\e933";
}
.icon-search:before {
  content: "\e934";
}
.icon-small-cross:before {
  content: "\e935";
}
.icon-small-minus:before {
  content: "\e936";
}
.icon-small-plus:before {
  content: "\e937";
}
.icon-small-tick:before {
  content: "\e938";
}
.icon-tag:before {
  content: "\e939";
}
.icon-trash:before {
  content: "\e93a";
}
.icon-warning-sign:before {
  content: "\e93b";
}
.icon-chevron-right:before {
  content: "\e93c";
}
.icon-user:before {
  content: "\e93e";
}
.icon-locate:before {
  content: "\e93f";
}
.icon-square:before {
  content: "\e940";
}
.icon-bug:before {
  content: "\e941";
  color: #495057;
}
.icon-info-sign:before {
  content: "\e942";
}
