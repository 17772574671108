html, body {
  cursor: default!important;
  height: 100%;
  color: $n-6;
  background-color: $n-1;
  font-family: inter, Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @extend .medium;
  @extend .noselect;
  z-index: -4;
  position: relative;
}

a {
  &:hover {
    cursor: default!important;
  }
}

.header-bar {
  height: 52px;
  background: $white;
}

.header {
  position: absolute;
  top: 52px;
  padding-left: 16px;
  height: 40px;
  line-height: 40px;
  background: $n-1;
  border-bottom: 1px solid $n-3;
}

#main {
  background-color: $white;
  color: $n-7;
  float: right;
  position: absolute;
  padding-left: 10px;
  top: 0px;
  bottom: 0;
  right: 0;
  left: 0;

  code {
    color: $n-7;
  }
}



#footer {

}


.tab-pane {
  height: 100%;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}
