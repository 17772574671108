.component {
  height: 100%;
  background-color: $white;


  &.debug-console {
    color: $y-8;
    font-size: 13px;
  }

  &.terminal-console {
    overflow-y: scroll;
    height: 100%;
    font-family: "RobotoMono", monospace;;
    font-size: 13px;
    color: $n-7;
    line-height: 18px;
  }

  &.error-layout {

    .row, .collapse > .row {
      min-height: 20px;

      &.even {
        background-color: $n-0;
      }

      .col-5,
      .col-2,
      .col-1 {
        border-right: 1px solid $n-3 !important;
        color: $n-9;

      }
    }

    .th {
      color: $n-6 !important;
    }

    .total {
      font-weight: bold;
      position: relative;
      color: $white;
      border-radius: 7px;
      min-width: 28px;
      height: 15px;
      line-height: 15px;
      display: inline-block;
      text-align: center;
      margin-right: 4px;
      padding-left: 10px;
      padding-right: 3px;

      &.error {
        background-color: $r-7;

      }

      &.warning {
        background-color: $y-7;

      }

      &.information {
        background-color: $b-7;
      }

      &:before {
        @extend .icon-ext;
        content: '\e907';
        border: none;
        position: absolute;
        left: 0;
        display: inline-block;
        height: 10px;
        line-height: 15px;
        color: #FFFFFF;
        font-size: 13px;
      }
    }

    .collapsed {

      .total {

        &:before {
          @extend .icon-ext;
          content: '\e909';
          border: none;
          position: absolute;
          left: 0;
          display: inline-block;
          height: 10px;
          line-height: 15px;
          color: #FFFFFF;
          font-size: 13px;
        }
      }
    }

    #accordion,
    #accordion2,
    #accordion3 {
      .row {
        .col-5 {
          padding-left: 48px !important;
        }
      }
    }

    .ico-error {
      position: relative;
      background: red;
      border-radius: 50%;
      color: white;
      width: 12px;
      height: 12px;
      margin-right: 3px;

      &:before {
        position: absolute;
        font-size: 13px;
        line-height: 14px;
      }
    }

    .ico-warning,
    .icon-info {
      font-size: 13px;
      margin-right: 3px;
    }

  }

  .collapse-row.collapsed + tr {
    display: none;
  }
}

.clsNotifications {
  font-family: "RobotoMono", monospace;;
  font-size: 13px;
  color: $n-7;

  .component {
    padding-top: 10px;
  }
}



