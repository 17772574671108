/*!
 * Primary color settings
 */

$theme-colors: (
        "primary": #0078d7,
        "danger": #ff4136
);

$white: #FFF;
$black: #000000;
$grey: #a0a0a0;
$blue1: #0078d7;

$orange: #f7941e;

//FONT
$font: "Segoe UI", Tahoma, sans-serif;
$font-size-default: 12px;

// Grid breakpoints
$grid-breakpoints: (xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1140px);


//colors

$n-0: #f8f9fa;
$n-1: #f1f3f5;
$n-2: #e9ecef;
$n-3: #dee2e6;
$n-4: #ced4da;
$n-5: #adb5bd;
$n-6: #868e96;
$n-7: #495057;
$n-8: #343a40;
$n-9: #212529;

$r-0: #fff5f5;
$r-1: #ffe3e3;
$r-2: #ffc9c9;
$r-3: #ffa8a8;
$r-4: #ff8787;
$r-5: #ff6b6b;
$r-6: #fa5252;
$r-7: #f03e3e;
$r-8: #e03131;
$r-9: #c92a2a;

$gra-0: #f8f0fc;
$gra-1: #f3d9fa;
$gra-2: #eebefa;
$gra-3: #e599f7;
$gra-4: #da77f2;
$gra-5: #cc5de8;
$gra-6: #be4bdb;
$gra-7: #ae3ec9;
$gra-8: #9c36b5;
$gra-9: #862e9c;

$v-0: #f3f0ff;
$v-1: #e5dbff;
$v-2: #d0bfff;
$v-3: #b197fc;
$v-4: #9775fa;
$v-5: #845ef7;
$v-6: #7950f2;
$v-7: #7048e8;
$v-8: #6741d9;
$v-9: #5f3dc4;

$b-0: #e7f5ff;
$b-1: #d0ebff;
$b-2: #a5d8ff;
$b-3: #74c0fc;
$b-4: #4dabf7;
$b-5: #339af0;
$b-6: #228be6;
$b-7: #1c7ed6;
$b-8: #1971c2;
$b-9: #1864ab;

$g-0: #ebfbee;
$g-1: #d3f9d8;
$g-2: #b2f2bb;
$g-3: #8ce99a;
$g-4: #69db7c;
$g-5: #51cf66;
$g-6: #40c057;
$g-7: #37b24d;
$g-8: #2f9e44;
$g-9: #2b8a3e;

$t-0: #e6fcf5;
$t-1: #c3fae8;
$t-2: #96f2d7;
$t-3: #63e6be;
$t-4: #38d9a9;
$t-5: #20c997;
$t-6: #12b886;
$t-7: #0ca678;
$t-8: #099268;
$t-9: #087f5b;

$y-0: #fff9db;
$y-1: #fff3bf;
$y-2: #ffec99;
$y-3: #ffe066;
$y-4: #ffd43b;
$y-5: #fcc419;
$y-6: #fab005;
$y-7: #f59f00;
$y-8: #f08c00;
$y-9: #e67700;

$o-0: #fff4e6;
$o-1: #ffe8cc;
$o-2: #ffd8a8;
$o-3: #ffc078;
$o-4: #ffa94d;
$o-5: #ff922b;
$o-6: #fd7e14;
$o-7: #f76707;
$o-8: #e8590c;
$o-9: #d9480f;

.n-0 {
  color: #f8f9fa;
}

.n-1 {
  color: #f1f3f5;
}

.n-2 {
  color: #e9ecef;
}

.n-3 {
  color: #dee2e6;
}

.n-4 {
  color: #ced4da;
}

.n-5 {
  color: #adb5bd;
}

.n-6 {
  color: #868e96;
}

.n-7 {
  color: #495057;
}

.n-8 {
  color: #343a40;
}

.n-9 {
  color: #212529;
}

.r-0 {
  color: #fff5f5;
}

.r-1 {
  color: #ffe3e3;
}

.r-2 {
  color: #ffc9c9;
}

.r-3 {
  color: #ffa8a8;
}

.r-4 {
  color: #ff8787;
}

.r-5 {
  color: #ff6b6b;
}

.r-6 {
  color: #fa5252;
}

.r-7 {
  color: #f03e3e;
}

.r-8 {
  color: #e03131;
}

.r-9 {
  color: #c92a2a;
}

.gra-0 {
  color: #f8f0fc;
}

.gra-1 {
  color: #f3d9fa;
}

.gra-2 {
  color: #eebefa;
}

.gra-3 {
  color: #e599f7;
}

.gra-4 {
  color: #da77f2;
}

.gra-5 {
  color: #cc5de8;
}

.gra-6 {
  color: #be4bdb;
}

.gra-7 {
  color: #ae3ec9;
}

.gra-8 {
  color: #9c36b5;
}

.gra-9 {
  color: #862e9c;
}

.v-0 {
  color: #f3f0ff;
}

.v-1 {
  color: #e5dbff;
}

.v-2 {
  color: #d0bfff;
}

.v-3 {
  color: #b197fc;
}

.v-4 {
  color: #9775fa;
}

.v-5 {
  color: #845ef7;
}

.v-6 {
  color: #7950f2;
}

.v-7 {
  color: #7048e8;
}

.v-8 {
  color: #6741d9;
}

.v-9 {
  color: #5f3dc4;
}

.b-0 {
  color: #e7f5ff;
}

.b-1 {
  color: #d0ebff;
}

.b-2 {
  color: #a5d8ff;
}

.b-3 {
  color: #74c0fc;
}

.b-4 {
  color: #4dabf7;
}

.b-5 {
  color: #339af0;
}

.b-6 {
  color: #228be6;
}

.b-7 {
  color: #1c7ed6;
}

.b-8 {
  color: #1971c2;
}

.b-9 {
  color: #1864ab;
}

.g-0 {
  color: #ebfbee;
}

.g-1 {
  color: #d3f9d8;
}

.g-2 {
  color: #b2f2bb;
}

.g-3 {
  color: #8ce99a;
}

.g-4 {
  color: #69db7c;
}

.g-5 {
  color: #51cf66;
}

.g-6 {
  color: #40c057;
}

.g-7 {
  color: #37b24d;
}

.g-8 {
  color: #2f9e44;
}

.g-9 {
  color: #2b8a3e;
}

.t-0 {
  color: #e6fcf5;
}

.t-1 {
  color: #c3fae8;
}

.t-2 {
  color: #96f2d7;
}

.t-3 {
  color: #63e6be;
}

.t-4 {
  color: #38d9a9;
}

.t-5 {
  color: #20c997;
}

.t-6 {
  color: #12b886;
}

.t-7 {
  color: #0ca678;
}

.t-8 {
  color: #099268;
}

.t-9 {
  color: #087f5b;
}

.y-0 {
  color: #fff9db;
}

.y-1 {
  color: #fff3bf;
}

.y-2 {
  color: #ffec99;
}

.y-3 {
  color: #ffe066;
}

.y-4 {
  color: #ffd43b;
}

.y-5 {
  color: #fcc419;
}

.y-6 {
  color: #fab005;
}

.y-7 {
  color: #f59f00;
}

.y-8 {
  color: #f08c00;
}

.y-9 {
  color: #e67700;
}

.o-0 {
  color: #fff4e6;
}

.o-1 {
  color: #ffe8cc;
}

.o-2 {
  color: #ffd8a8;
}

.o-3 {
  color: #ffc078;
}

.o-4 {
  color: #ffa94d;
}

.o-5 {
  color: #ff922b;
}

.o-6 {
  color: #fd7e14;
}

.o-7 {
  color: #f76707;
}

.o-8 {
  color: #e8590c;
}

.o-9 {
  color: #d9480f;
}

.large {
  font-size: 16px;
  line-height: 20px;
}

.medium {
  font-size: 12px;
  line-height: 14px;
}

.small {
  font-size: 11px;
  line-height: 16px;
}

.caption {
  font-size: 10px;
  line-height: 12px;
}

.default {
  color: $n-7;
}

.muted {
  color: $n-6;
}
.black {
  color: $black;
}

a {
  color: $b-6;
}

.sub-headline {
  font-weight: bold;
  color: $n-6;
}

.dark {
  .default {
    color: $n-0;
  }

  .muted {
    color: $n-5;
  }

  .sub-headline {
    font-weight: bold;
    color: $n-5;
  }
}

.f-14 {
  font-size: 14px!important;
}
