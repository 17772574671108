
#footer-sidebar {
	background-color: $n-1;
	border-top: solid 1px $n-2;
	width: 100%;
	height: 22px;
	bottom: 0;
	position: absolute;
	text-align: right;
	font-size: 12px;
	line-height: 21px;
	z-index: 2;
	color: $n-7;

	[class^="icon-"] {
		font-size: 13px;
	}
	
	a {
		&:hover {
			text-decoration: none;
			color: $n-9;
		}
	}

	#menuContainer {
		margin: 0;
		padding: 0 0 0 10px;
		display: inline-block;;

		li {
			display: inline-block;;
			padding-right: 10px;
			padding-left: 10px;

			&:hover {
				background-color: $n-3;
			}
		}
	}

	.text-right {
		> span {

			margin-left: 16px;

			a {
				color: $n-7;

				&:hover {
					text-decoration: none;
				}

				span {
					padding-left: 8px;
				}
			}
		}
	}

}

#footer-editor {
	margin-left: -11px;

	background-color: $white;
	border-top: solid 1px $n-2;
	border-bottom: solid 1px $n-2;
	border-left: solid 1px $n-2;

	width: 100%;
	height: 22px;
	bottom: 0;
	position: absolute;
	text-align: right;
	font-size: 12px;
	line-height: 21px;
	z-index: 2;
	color: $n-7;

	.text-right {
		> span {

			margin-left: 16px;

			a {
				color: $n-7;

				&:hover {
					text-decoration: none;
				}

				span {
					padding-left: 8px;
				}
			}
		}
	}

	[class^="icon-"] {
		font-size: 13px;
	}
}
