.toolbar {

  .icons {

    a {
      margin-right: 6px;

      &:hover {
        text-decoration: none;
      }
    }
  }

  span {

    padding: 4px;

    &:hover {

      background-color: $n-3;
      padding: 4px;
      border-radius: 4px;
    }
  }

  .text-right {
    a {
      height: 24px;
      margin-left: 8px;

      &:hover {
        text-decoration: none;
      }
    }

    .sign-in {
      color: $n-7;
      padding: 4px 7px 5px 4px!important;

      span {
        color: $b-7;
        padding-top: 0;
        padding-bottom: 0;
      }

      &:hover {
        color: $b-9;
        background-color: $n-3;
        padding: 4px;
        border-radius: 4px;
        span {
          color: $b-9;
        }
      }


    }
  }

  .gra-6 {
    &:hover {
      @extend .gra-8;
    }
  }

  #menuContainerToolbar {
    list-style: none;
    padding: 0;

    li {
      display: inline-block;
    }
  }
}
