.nav-tabs {

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: $n-2;
    bottom: 0;
    z-index:3;
  }

  background: $n-1;
  height: 32px;
  overflow-x: auto;
  overflow-y: hidden;
  border-bottom: none;

  li {

    position: relative;
    line-height: 32px;

    a {
      width: 100%;
      display: inline-block;
      height: 32px;
      padding: 0 80px 0 15px;
      text-decoration: none;
      color: $n-6;

      &.add-new-tab {
        border-left: 1px solid $n-2;
        padding-left: 10px;
        padding-right: 10px;
        margin-top: 6px;
        height: 20px;
        line-height: 20px;
      }
    }

    &:hover {
      a {
        color: $n-7;
      }
    }

    span {
      display: none;
      font-size: 8px;
      cursor: default;
      position: absolute;
      right: 6px;
      top: 0;
      color: $black;
      div {
        margin-top: 4px!important;
      }
    }

    &:hover {

      span {
        display: inline-block;
        div {
          margin-top: 0px!important;
        }
      }
    }

    &.active {
      line-height: 28px;
      a {
        color: $n-7;
        background: white;
        border-top: 2px solid $b-7;
      }

      span {
        display: inline-block;
        div {
          margin-top: 4px!important;
        }
      }
      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: white;
        bottom: 0px;
        z-index:4;
      }

      &:hover {

        span {
          div {
            margin-top: 4px!important;
          }
        }
      }
    }

  }

  .icon-plus {
    margin-top: -4px;

    &:before {
      font-size: 13px;
    }
  }
}

.tabs-icons {
  background: $n-1;

  span {
    margin-top: 3px!important;
    padding: 4px;

    &:hover {

      background-color: $n-3;
      padding: 4px;
      border-radius: 4px;
    }
  }
}

.tab-content {
  position: relative;
  height: calc(100% - 62px);
  display: block;
  overflow-y: auto;

  .tab-page {
  }
}
