.tree-top {
  min-width: 200px;
  margin: 0 !important;
  padding-left: 0;
  padding-right: 0;

  .title {
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    line-height: 16px;
    color: $n-6;
  }

  .icons {
    margin-top: 1px;
    padding-right: 1px;

    a {
      margin-left: 4px;

      &:hover {
        text-decoration: none;
      }

      span {

        padding: 4px;

        &:hover {

          background-color: $n-3;
          padding: 4px;
          border-radius: 4px;
        }
      }
    }
  }
}

.tree-list {
  min-width: 400px;
  list-style-type: none;
  font-size: 1em;
  line-height: 1.8em;
  padding-left: 15px;

  ul {
    list-style-type: none;
    font-size: 1em;
    line-height: 1.8em;
  }

  li {

    line-height: 24px;

    &:not(.current) {
      a {

        &:hover {
          &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 22px;
            background: $n-3;
            left: 0;
            right: 0;
            z-index: -3;
          }
        }
      }
    }

    &.current {
      a {

        &:hover {
          &:after {
            background: transparent !important;
          }
        }
      }
    }

    a {
      text-decoration: none;
      color: $n-7;
    }

    &.current {
      position: inherit;

      a {
        color: $white !important;
        position: relative;
        z-index: 1;
      }

      &:before {
        color: $white !important;
        position: relative;
        z-index: 1;
      }

      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 22px;
        background: $b-9;
        left: 0;
        right: 0;
        z-index: 0;
      }
    }

    &.select {
      position: inherit;

      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 25px;
        background: $n-3;
        left: 0;
        right: 0;
        z-index: -3;

      }
    }

    &:not(.folder-root) {
      &:before {
        @extend .icon-ext;
        content: '\e912';
        border: none;
        display: inline-block;
        padding-right: 5px;
        padding-bottom: 2px;
        margin-left: 17px;
        color: $n-7;
      }

      &:hover {
        a {
          color: $n-7;
        }

        &:not(.current) {
          &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 25px;
            background: $n-3;
            left: 0;
            right: 0;
            z-index: -3;
          }

          &:before {
            color: $n-7;
          }
        }
      }
    }

    &:before {
      font-size: 14px !important;
    }
  }

  .folder-root {

    > ul {
      padding-left: 15px;
      transition: all .3s ease-in-out;
      overflow: hidden;
    }

    > a {
      text-decoration: none;
      color: $n-7;
      display: flex;

      &:before {

        @extend .icon-ext;
        content: '\e909';
        border: none;
        display: inline-block;
        padding-top: 4px;
        padding-right: 3px;
        padding-bottom: 1px;
        color: $n-7;
      }
    }

    &.open {

      > ul {

        min-width: 170px;
        opacity: 1;
        display: block;
        max-height: 1000px;
      }

      > a {

        &:before {
          @extend .icon-ext;

          content: '\e907';
          border: none;
          display: inline-block;
          padding-top: 4px;
          padding-right: 3px;
          color: $n-7;
        }
      }
    }

    &.closed {
      > ul {
        transition: all .1s ease-in-out;
        position: relative;
        opacity: 0;
        max-height: 0px;
      }
    }
  }

  .current {
    a {
      color: $white;
    }
  }
}

#tree {
  height: calc(100% - 148px) !important;
}
